import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import InnerText from 'innertext';

const SEO = ({title, description, keywords, image}) => (
    <StaticQuery
        query={query}
        render={({
            site :{
                siteMetadata: {
                    DefaultTitle,
                    DefaultDescription,
                    DefaultKeywords,
                    DefaultImage,
                    DefaultURL
                }
            }
        }) => {
            const seo = {
                title: title || DefaultTitle,
                description: description || DefaultDescription,
                keywords: keywords || DefaultKeywords,
                image: `${image || DefaultURL+DefaultImage}`
            }
            return(
                <Helmet>
                    <html lang="en" />
                    <title>{InnerText(seo.title)}</title>
                    <meta name="image" content={seo.image} />
                    <meta name="description" content={InnerText(seo.description)} />
                    <meta name="keywords" content={seo.keywords} />
                    <meta name="robots" content="index, follow" />
                    <link rel="alternate" href="https://alkye.com/" hreflang="en"/>
                    {/* <base href={DefaultURL} target="_blank" /> */}

                    <script src="https://www.google.com/recaptcha/api.js"></script>
                </Helmet>
            )
        }}
    />
)

export default SEO;

const query = graphql`
{
    site {
      siteMetadata {
        DefaultTitle: title
        DefaultDescription: description
        DefaultKeywords: keywords
        DefaultImage: image
        DefaultURL: url
      }
    }
}
`